const token = localStorage.getItem('access_token')

const parts = window.location.host.split('.');
parts[0] = 'api';
export const URL = process.env.NODE_ENV === 'development' ? 'https://api.cpvptz.upirko.site' : `${window.location.protocol}//${parts.join('.')}`;

export const headers = {
	'charset': 'utf-8',
	'authorization': `Bearer ${token}`
};

export const downloadLink = (id) => `${URL}/files/${id}/download`;
