import * as React from 'react';
import {Grid, Button} from '@mui/material';
import CustomBox from "../CustomBox";
import { downloadLink } from '../../api/apiHelper';

const success = {
	position: 'absolute',
	width: '200px',
	height: '60px',
	background: 'green',
	fontFamily: 'Tahoma, san-serif',
	fontSize: '12px',
	textAlign: 'center',
	left: '50%',
	top: '50%',
	transform: 'translate(-50%, -50%)',
	zIndex: '2'
}

const error = {
	position: 'absolute',
	width: '200px',
	height: '60px',
	background: 'red',
	fontFamily: 'Tahoma, san-serif',
	fontSize: '12px',
	textAlign: 'center',
	left: '50%',
	top: '50%',
	transform: 'translate(-50%, -50%)',
	zIndex: '2'
}

const QuestionsGroup = ({
	title,
	items,
	onClickQuestion,
	isExam = false,
	isEndOfExam = false,
	isTopic = false,
	...props
}) => {
	return (
		<Grid item xl={3} lg={3} md={6} sm={12} xs={12} {...props}>
			<CustomBox
				sx={{
					height: {
						xs: 'auto',
						sm: 'auto',
						md: 'auto',
						lg: isTopic? 'auto' : 'calc(100vh - 350px)',
						xl: isTopic? 'auto' : 'calc(100vh - 360px)'}
				}}
				style={{
					padding: 0,
					border: props.borderColor ? `3px solid  ${props.borderColor}` : '',
					borderRadius: isTopic? '0' : '20px',
					overflow: 'hidden',
			}}
			>
				<Grid container>
					<Grid
						container
						spacing={isTopic ? 2 : 0}
						xs={12}
						sm={12}
						md={12}
						lg={12}
						xl={12}
					>
						{items?.map((item, idx) => {
							const hasAnswer = item?.userAnswer;
							const isCorrect = item?.userAnswer?.correctAnswerID === item?.userAnswer?.choisedAnswerID;

							const imageSrc = item.imageId ? downloadLink(item.imageId) : item.image;

							return (
								<Grid
									item
									key={item.name}
									xs={12}
									sm={12}
									md={isTopic ? 4 : 12}
									lg={isTopic ? 4 : 12}
									xl={isTopic ? 4 : 12}
									flexDirection={isTopic ? 'row' : 'column'}
									sx={{
										position: 'relative',
										overflow: 'hidden'
									}}
								>
									{(item.count && !isTopic) && (<div style={{
										position: 'absolute',
										top: '6px',
										left: '12px',
										fontFamily: 'Tahoma, san-serif',
										fontSize: '12px',
									}}>
										{item.count}
									</div>)}
									<Button
										variant="outlined"
										fullWidth={!isTopic}
										style={{
											display: 'flex',
											flexDirection: 'column',
											width: '100%',
											margin: isTopic? '0' : '0',
											overflow: 'hidden',
											background: hasAnswer ? isExam ? 'darkgray' : 'lightgray' : 'initial',
											// height: '20%',
											border: isTopic ? '1px solid rgba(25, 118, 210, 0.5)' : 'none',
											borderBottom: isTopic ? '' : idx !== 4 ? '1px solid black' : 'transparent',
											borderRadius: '0',
											color: '#000',
											fontFamily: 'Tahoma, san-serif',
											fontSize: '12px',
											textTransform: 'none'
										}}
										sx={{
											fontSize: {xs: '10px', sm: '12px', md: '12px', lg: '12px', xl: '12px'},
											minHeight: {xs: 'auto', sm: 'auto', md: 'auto', lg: 'auto', xl: 'auto'},
											height: {xs: '150px', sm: '180px', md: '210px', lg: 'calc((100vh - 360px) / 5)', xl: 'calc((100vh - 360px) / 5)'},
											position: 'relative'
										}}
										color={
											isExam
												? isEndOfExam
													? hasAnswer
														? isCorrect ? 'success' : 'error'
														: 'primary'
													: "primary"
												: hasAnswer
													? isCorrect ? 'success' : 'error'
													: 'primary'
										}
										onClick={() => onClickQuestion(item.count)}
									>
										{
											isExam
												? isEndOfExam
													? hasAnswer
														? isCorrect ? (
															<div style={success}>Ответ правильный</div>
														) : (
															<div style={error}>Ответ неправильный</div>
														)
														: ''
													: ''
												: hasAnswer
													? isCorrect ? (
														<div style={success}>Ответ правильный</div>
													) : (
														<div style={error}>Ответ неправильный</div>
													)
													: ''
										}
										{imageSrc && (
											<img
												style={{ maxHeight: '50%', margin: '8px 0 10px 0', borderRadius: '4px', opacity: '0.7'}}
												src={imageSrc}
												alt=""
											/>
										)}
										<div>{imageSrc ? `${item.name.slice(0, 50)}...` : item.name}</div>
									</Button>
								</Grid>
							)
						})}
					</Grid>
				</Grid>
			</CustomBox>
		</Grid>
	)
};

export default QuestionsGroup;